import { Button } from 'antd'
import * as React from "react"
import './ExtraOffer.scss';

const ExtraOffer = (props) => {
  const descriptionStyles = {
    color: 'rgba(29, 49, 80, 0.5)',
  };

  const priceStyles = {
    fontSize: '1.25rem',
    fontWeight: '600',
  };

  const priceMetaStyles = {
    color: 'rgba(29, 49, 80, 0.5)',
  };

  const metaStyles = {
    flexBasis: '33.3%',
  };

  const featureStyles = {
    flexGrow: '1',
  };

  const priceBoxStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  };

  const featureHeadlineStyles = {
    color: 'rgba(29, 49, 80, 0.5)',
    textTransform: 'uppercase',
    fontWeight: '500',
    marginBottom: '20px',
  };

  return (
    <>
      <div className={'extra-offer ' + (props.disabled ? 'extra-offer--disabled ' : '')}>
        <div style={metaStyles}>
          <h3>{props.title}</h3>
          <div style={descriptionStyles}>
            {props.description}
          </div>
        </div>

        <div style={featureStyles}>
          <div style={featureHeadlineStyles}>Leistungsumfang</div>
          {props.features}
        </div>
  
        <div style={priceBoxStyles}>
          <div style={{'marginBottom': '10px'}}>
            <span style={priceStyles}>{props.price}&euro; </span>
            <span style={priceMetaStyles}>einmalig*</span>
          </div>

          <small>Bitte per E-Mail anfragen</small>
        </div>
      </div>
    </>
  )
}

export default ExtraOffer;