import * as React from "react"
import Package from './Package'
import './PackageList.scss'

const PackageList = (props) => {
  const featureHeadlineStyles = {
    color: 'rgba(29, 49, 80, 0.5)',
    textTransform: 'uppercase',
    fontWeight: '500',
  };

  const listStyles = {
    listStyleType: 'none',
    padding: 0,
    margin: 0,
    lineHeight: 2,
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
  };

  const inactiveStyles = {
    color: 'rgba(29, 49, 80, 0.3)',
    textDecoration: 'line-through',
  };

  return (
    <div className="package-list">
      <Package disabled="true"
        title="Kommt bald"
        description="Die perfekte Lösung für kleine Vereine mit großen Zielen, die eine Möglichkeit zur Verwaltung ihres Vereins benötigen."
        price="??,??"
        features={
          <ul style={listStyles}>
            <li>
              <span style={featureHeadlineStyles}>Highlights</span>
              <ul>
                <li>500 Personen &amp; 500 Tiere</li>
                <li>Risikofrei 30 Tage testen, monatlich kündbar</li>
                <li>Startbereit in wenigen Sekunden</li>
                <li>§11 TierSchG-konforme Lösung</li>
                <li>2 GB Speicher für Bilder und Dokumente</li>
                <li>Zugriff per Smartphone, Tablet oder PC</li>
                <li style={inactiveStyles}>Wartung und Administration der Installation</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Stammdatenbank</span>
              <ul>
                <li>
                  Stammdatenbank für Personen und Tiere (Vermittlungs- und Pflegetiere, Adoptanten, Mitglieder, Pflegestellen etc.)
                </li>
                <li>Automatisches Bestandsbuch</li>
                <li>Medien- &amp; Dokumentenverwaltung</li>
                <li style={inactiveStyles}>Veterinäramtszugang</li>
                <li>Aufgabenverwaltung</li>
                <li>Dokumentenvorlagen</li>
                <li>Daten Im- &amp; Export</li>
                <li style={inactiveStyles}>Erweiterbare Datenbank (Zusatzfelder)</li>
                <li style={inactiveStyles}>Eigene Listen</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Tiervermittlung</span>
              <ul>
                <li style={inactiveStyles}>Vermittlungsübersicht</li>
                <li style={inactiveStyles}>Checkliste für jede Vermittlung</li>
                <li style={inactiveStyles}>Transportplanung</li>
                <li style={inactiveStyles}>Online-Bewerbungsformular für Interessenten und Pflegestellen</li>
                <li style={inactiveStyles}>Bewerbermanagement</li>
                <li style={inactiveStyles}>Automatische Vertragserstellung</li>
                <li style={inactiveStyles}>Digitaler Schutzvertrag</li>
                <li style={inactiveStyles}>Erstellung von Transportlisten</li>
                <li style={inactiveStyles}>VK/NK-Suche</li>
                <li style={inactiveStyles}>Übergeber-Mails</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Schnittstellen</span>
              <ul>
                <li style={inactiveStyles}>Schnittstelle zur Anbindung von Drittanbietern</li>
                <li style={inactiveStyles}>TASSO Tierregister</li>
                <li style={inactiveStyles}>Slack-Integration</li>
                <li style={inactiveStyles}>Wordpress-Plugin</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Support</span>
              <ul>
                <li>Eingeschränkter Support per E-Mail (Systemfehler, Fehlermeldungen)</li>
                <li>Zugriff auf Handbuch &amp; Videoanleitungen</li>
                <li>Kostenlose Updates</li>
              </ul>
            </li>
          </ul>
        }
      ></Package>
      <Package
        highlight
        title="petoffice Basic"
        description="Für mittelgroße Vereine, die immer den Überblick über die aktuelle Vermittlungsarbeit und tiefstmögliche Integration in andere System haben wollen."
        price="19,00"
        features={
          <ul style={listStyles}>
            <li>
              <span style={featureHeadlineStyles}>Highlights</span>
              <ul>
               <li>Unbegrenze Menge an Tieren und Personen</li>
                <li>Risikofrei 30 Tage testen, monatlich kündbar</li>
                <li>Startbereit in wenigen Sekunden</li>
                <li>§11 TierSchG-konforme Lösung</li>
                <li>20 GB Speicher für Bilder und Dokumente</li>
                <li>Zugriff per Smartphone, Tablet oder PC</li>
                <li style={inactiveStyles}>Wartung und Administration der Installation</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Stammdatenbank</span>
              <ul>
                <li>
                  Stammdatenbank für Personen und Tiere (Vermittlungs- und Pflegetiere, Adoptanten, Mitglieder, Pflegestellen etc.)
                </li>
                <li>Automatisches Bestandsbuch</li>
                <li>Medien- &amp; Dokumentenverwaltung</li>
                <li>Veterinäramtszugang</li>
                <li>Aufgabenverwaltung</li>
                <li>Dokumentenvorlagen</li>
                <li>Daten Im- &amp; Export</li>
                <li>Erweiterbare Datenbank (Zusatzfelder)</li>
                <li>Eigene Listen</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Tiervermittlung</span>
              <ul>
                <li>Vermittlungsübersicht</li>
                <li>Checkliste für jede Vermittlung</li>
                <li>Transportplanung</li>
                <li>Online-Bewerbungsformular für Interessenten und Pflegestellen</li>
                <li>Bewerbermanagement</li>
                <li>Automatische Vertragserstellung</li>
                <li>Digitaler Schutzvertrag</li>
                <li>Erstellung von Transportlisten</li>
                <li>VK/NK-Suche</li>
                <li>Übergeber-Mails</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Schnittstellen</span>
              <ul>
                <li>Schnittstelle zur Anbindung von Drittanbietern</li>
                <li>TASSO Tierregister</li>
                <li>Slack-Integration</li>
                <li style={inactiveStyles}>Wordpress-Plugin</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Support</span>
              <ul>
                <li>Support per E-Mail</li>
                <li>Zugriff auf das Handbuch</li>
                <li>Kostenlose Updates</li>
              </ul>
            </li>
          </ul>
        }
      ></Package>
      <Package
        title="Kommt bald" disabled="true"
        description="Für die höchsten Ansprüche: Wir verwalten deine Installation, kümmern uns um Vertragsvorlagen, Benutzer, Zusatzfelder und vieles mehr."
        price="??,??"
        features={
          <ul style={listStyles}>
                        <li>
              <span style={featureHeadlineStyles}>Highlights</span>
              <ul>
               <li>Unbegrenze Menge an Tieren und Personen</li>
                <li>Risikofrei 30 Tage testen, monatlich kündbar</li>
                <li>Startbereit in wenigen Sekunden</li>
                <li>§11 TierSchG-konforme Lösung</li>
                <li>100 GB Speicher für Bilder und Dokumente</li>
                <li>Zugriff per Smartphone, Tablet oder PC</li>
                <li>Wartung und Administration der Installation</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Stammdatenbank</span>
              <ul>
                <li>
                  Stammdatenbank für Personen und Tiere (Vermittlungs- und Pflegetiere, Adoptanten, Mitglieder, Pflegestellen etc.)
                </li>
                <li>Automatisches Bestandsbuch</li>
                <li>Medien- &amp; Dokumentenverwaltung</li>
                <li>Veterinäramtszugang</li>
                <li>Aufgabenverwaltung</li>
                <li>Dokumentenvorlagen</li>
                <li>Daten Im- &amp; Export</li>
                <li>Erweiterbare Datenbank (Zusatzfelder)</li>
                <li>Eigene Listen</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Tiervermittlung</span>
              <ul>
                <li>Vermittlungsübersicht</li>
                <li>Checkliste für jede Vermittlung</li>
                <li>Transportplanung</li>
                <li>Online-Bewerbungsformular für Interessenten und Pflegestellen</li>
                <li>Bewerbermanagement</li>
                <li>Automatische Vertragserstellung</li>
                <li>Digitaler Schutzvertrag</li>
                <li>Erstellung von Transportlisten</li>
                <li>VK/NK-Suche</li>
                <li>Übergeber-Mails</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Schnittstellen</span>
              <ul>
                <li>Schnittstelle zur Anbindung von Drittanbietern</li>
                <li>TASSO Tierregister</li>
                <li>Slack-Integration</li>
                <li>Wordpress-Plugin</li>
              </ul>
            </li>
  
            <li>
              <span style={featureHeadlineStyles}>Support</span>
              <ul>
                <li>Support per E-Mail und Telefon</li>
                <li>Zugriff auf Handbuch &amp; Videoanleitungen</li>
                <li>Kostenlose Updates</li>
              </ul>
            </li>
          </ul>
        }
      ></Package>
    </div>
  )
}

export default PackageList;