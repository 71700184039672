import { Button } from 'antd'
import * as React from "react"
import TenantCreation from './TenantCreation';
import './Package.scss';

const PackageList = (props) => {
  const [showTenantCreation, setShowTenantCreation] = React.useState(false)

  const descriptionStyles = {
    color: 'rgba(29, 49, 80, 0.5)',
  };

  const priceStyles = {
    fontSize: '1.25rem',
    fontWeight: '600',
  };

  const priceMetaStyles = {
    color: 'rgba(29, 49, 80, 0.5)',
  };

  return (
    <>
      <div className={'package ' + (props.disabled ? 'package--disabled ' : '') + (props?.highlight ? 'package--highlight ' : '')}>
        <h3>{props.title}</h3>
        <div style={descriptionStyles}>
          {props.description}
        </div>
        <div>
          <span style={priceStyles}>{props.price}&euro; </span>
          <span style={priceMetaStyles}>pro Monat*</span>
        </div>
        <Button type="primary" onClick={() => setShowTenantCreation(true)}>Jetzt kostenlos testen</Button>
        <div>
          {props.features}
        </div>
  
        <div>
          <span style={priceStyles}>{props.price}&euro; </span>
          <span style={priceMetaStyles}>pro Monat*</span>
        </div>
        <Button type="primary" onClick={() => setShowTenantCreation(true)}>Jetzt kostenlos testen</Button>

        <TenantCreation visible={showTenantCreation} onCancel={() => setShowTenantCreation(false)}  onOk={() => setShowTenantCreation(false)}></TenantCreation>
      </div>
    </>
  )
}

export default PackageList;