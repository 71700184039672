import * as React from "react"
import Container from '../components/Container'
import IndentedContent from '../components/helpers/IndentedContent'
import ContentPage from '../components/layout/ContentPage'
import PackageList from '../components/PackageList'
import SectionEntry from '../components/SectionEntry'
import SectionEntryContent from '../components/SectionEntryContent'
import SectionEntryTitle from '../components/SectionEntryTitle'
import ExtraOffer from '../components/ExtraOffer'
import SEO from '../components/seo'

// markup
const PricesPage = () => {
  return (
    <ContentPage>
      <SEO title="Preise" />

      <Container containerStyles={{ pointerEvents: 'none', position: 'absolute', pointerEvents: 'none', top: 0, left: 0, right: 0, height: '700px', backgroundImage: 'radial-gradient(at left top, rgba(81, 144, 224, 0.08) 20%, transparent 80%), radial-gradient(at right center, rgba(122, 81, 224, 0.1) 20%, transparent 75%)' }}>
      </Container>
      <Container>
        <IndentedContent>
        <SectionEntry>
            <SectionEntryTitle>Pakete und Preise</SectionEntryTitle>

            <SectionEntryContent>
              Wir haben die passende Lösung für deinen Verein. Egal, ob du 5 oder 5000 Tiere vermittelst.
            </SectionEntryContent>
          </SectionEntry>

          <PackageList></PackageList>

          <SectionEntry>
            <SectionEntryTitle>Zusätzliche Leistungen</SectionEntryTitle>

            <SectionEntryContent>
              Erweitere deine petoffice-Installation oder profitiere von zusätzlichen Dienstleistungen.
            </SectionEntryContent>
          </SectionEntry>

          <ExtraOffer title="Ersteinrichtung" price="299,00" description={
            <p>Du kannst mittels der Informationen in unserem Handbuch die Ersteinrichtung einfach selbst vornehmen - oder wir übernehmen das für dich und liefern deine Installation schlüsselfertig ab.</p>
          } features={
            <ul>
              <li>Grundlegende Einstellungen zu Verein, Schutzgebühren, Tierarten etc.</li>
              <li>Anlegen benötigter Zusatzfelder</li>
              <li>Import von Bestandsdaten aus Excel-Tabellen</li>
              {/*<li><b>Anpassung der Vertragsdokumente an eure Vorlagen</b></li>*/}
              <li>Einrichtung der TASSO-Schnittstelle</li>
              <li>Initialer Import TASSO-Status für alle Tiere</li>
              <li>Erstellen Benutzerzugänge für Vereinsmitglieder</li>
            </ul>
          }></ExtraOffer>

          <br />

          <ExtraOffer title="Erstellung Dokumentenvorlagen" price="149,00" description={
            <p>Du kannst mittels der Informationen in unserem Handbuch eure Vereinsvorlagen einfach selbst anpassen. Wenn dir das zu viel Arbeit ist, können wir das auch für dich übernehmen.</p>
          } features={
            <ul>
              <li>Erstellung aller nötigen Dokumentenvorlagen auf Basis der bestehenden Vertragsvorlagen</li>
              <li>Umsetzung im Rahmen des bestehenden Funktionsumfangs in petoffice</li>
              <li>Import und Test der Vorlagen in eurem Vereinskonto</li>
            </ul>
          }></ExtraOffer>

          <small style={{ display: 'block', textAlign: 'center', marginTop: '5em', color: 'rgba(29, 49, 80, 0.5)' }}>* Als Kleinunternehmer im Sinne von § 19 Abs. 1 Umsatzsteuergesetz (UStG) wird Umsatzsteuer nicht berechnet.</small>
        </IndentedContent>
      </Container>
    </ContentPage>
  )
}

export default PricesPage
